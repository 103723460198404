<template>
  <div
    class="w-full p-2"
    @click="signalNews(item) && toggleBackground(item.id)"
    :class="{
      'border-l-2 border-blue-400 bg-blue-900/[0.15]': recentNewsItem,
      'bg-gray-800/50 ': newsGroupOpened(item.id),
      'bg-blue-800': activeItemId === item.id
    }"
  >
    <div class="flex justify-between text-xs text-gray-400">
      <BaseTooltip>
        <template #target>
          <span class="text-xs text-gray-400">
            {{ sourceName }}
          </span>
        </template>
        <template #default v-if="item.sources.length > 1">{{ item.sources.map(o => o.name).join(', ') }}</template>
      </BaseTooltip>
      <span class="block whitespace-nowrap">{{ minimalTimeWithinToday(item.timestamp) }}</span>
    </div>
    <a
      v-bind="{ ...(!signalNews(item) && { href: item?.link }) }"
      @click="signalNews(item) && showOnChainSidebar(item.link)"
      target="_blank"
      class="flex items-center"
    >
      <p
        class="mt-0.5 line-clamp-2 cursor-pointer text-xs font-medium text-gray-100"
        v-html="item.group_similar_news ? item.grouped_headline : item.translated_headline || item.headline"
      />
      <ChevronRightIcon v-if="parentComponent === 'on-chain-dashboard'" class="ml-1 h-4 w-4 flex-shrink-0" />
    </a>
    <BaseButton
      v-if="item.similarNews"
      type="secondary"
      size="xs"
      class="mt-1 rounded-full"
      @press="toggleVisibility(item.id)"
    >
      <span class="mx-2 flex items-center">
        <span class="pr-0.5">
          {{ newsGroupOpened(item.id) ? 'Hide' : 'Show' }} {{ item.grouped.length }} Related Headlines
        </span>
        <ChevronDoubleDownIcon v-if="!newsGroupOpened(item.id)" class="h-3 w-3"></ChevronDoubleDownIcon>
        <ChevronDoubleUpIcon v-if="newsGroupOpened(item.id)" class="h-3 w-3"></ChevronDoubleUpIcon>
      </span>
    </BaseButton>
    <div v-if="!hideTags" class="mt-1 flex w-full justify-between text-xxs text-secondary">
      <div class="flex flex-wrap">
        <NewsFeedItemTags :tags="item.tags" :item="item" @handle-tag-click="handleTagClick" size="xs" />
      </div>
    </div>
  </div>
  <div class="border border-gray-700 border-opacity-25" />
  <div v-if="newsGroupOpened(item.id)" :class="`pl-[1.0rem] ${newsGroupOpened(item.id) && 'bg-gray-800/50'}`">
    <VirtualScrollItemSimpleNews
      v-for="newsItem in item.grouped"
      :key="newsItem.id"
      :index="index"
      :source="newsItem"
      :is-grouped="true"
    />
  </div>
</template>
<script>
import { ChevronDoubleDownIcon, ChevronRightIcon, ChevronDoubleUpIcon } from '@heroicons/vue/20/solid';
import NewsFeedItemTags from '@/components/news_feeds/NewsFeedItemTags.vue';
import FilterMixin from '@/mixins/filters';
import { router } from '@inertiajs/vue3';
import { showOnChainSidebar, signalNews } from '@/composeables/news';
import { DateTime } from 'luxon';
export default {
  mixins: [FilterMixin],
  name: 'VirtualScrollItemSimpleNews',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default() {
        return {};
      }
    },
    hideTags: {
      type: Boolean,
      default: false
    },
    showGroupedNewsItems: {
      type: Array,
      default: () => []
    },
    toggleGroupedVisibility: {
      type: Function
    },
    parentComponent: {
      type: String,
      default: () => ''
    },
    toggleBackground: {
      type: Function
    },
    activeItemId: {
      type: Number,
      default: () => null
    }
  },
  components: {
    NewsFeedItemTags,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon
  },
  beforeMount() {
    this.item = this.source;
  },
  computed: {
    sourceName() {
      return this.item.sources.length > 1
        ? `${this.item.sources[0]?.name} and ${this.item.sources.length - 1} more`
        : this.item.sources[0]?.name;
    },
    recentNewsItem() {
      const now = DateTime.now();
      const minutes = now.diff(DateTime.fromISO(this.item.timestamp), 'minutes').minutes;
      return minutes < 5 ? true : false;
    },
    companyTagGroups() {
      return this.$store.state.companyTagGroups;
    }
  },
  methods: {
    showOnChainSidebar,
    signalNews,
    handleTagClick: function (tag, newsItem) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        this.$store.dispatch('enableCoinSidebar', { coin: tag.coin.uid, newsItem: newsItem });
      }

      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        const company = this.$store.state.companySlugs.find(x => x.tag == tag.id);
        if (company) {
          router.visit(`/company/${company.company_uid}`);
        }
      }
    },
    toggleVisibility(id) {
      this.toggleGroupedVisibility(id);
    },
    newsGroupOpened(id) {
      return this.showGroupedNewsItems.includes(id);
    }
  }
};
</script>
